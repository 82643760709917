<template>
  <div class="tree-select" :class="{ open: isDropdownVisible }">
    <nav class="search-left-icon">
      <a v-if="(search || dataMode === 'search') && isDropdownVisible" class="ant-search-clear" @click="clearSearch"
        :style="{ top: '6px' }">
        <close-circle-filled />
      </a>
      <a-input-search v-model:value="search" :placeholder="placeholderCurrent" @click="isDropdownVisible = true"
        autocomplete="off" :loading="loadingProgress" id="search-input" @search="fetchList" style="
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
" />
    </nav>
    <div class="tree-select__dropdown" :class="{ visible: isDropdownVisible }">
      <div class="tree-select__dropdown__content" :style="{ maxHeight: maxHeight || 'auto' }">
        <div v-if="!isRootLoaded && !hideStructure">
          <div class="disabled-color-text small" :style="{ padding: '0.5rem 1rem' }" v-if="isDropdownVisible">
            <a-table class="w-100" size="small" loading :locale="{ emptyText: 'Подождите...' }" :show-header="false" />
          </div>
        </div>
        <div v-else-if="dataMode === 'search'">
          <object-search-table class="w-100" :compact-mode="true" :pagination="pagination" @change="handleTableChange"
            :data-source="data" :loading="loading">
            <template #actions="{ record }">
              <a-button size="small" type="primary"
                :disabled="(record?.id === parentId || (parentId === 'root' && record?.name === 'Верхний уровень')) && disableRoot"
                @click="handleSelect(record)">
                Выбрать
              </a-button>
            </template>
          </object-search-table>
        </div>
        <div v-else>
          <div v-if="hideStructure">
            <div class="disabled-color-text small" :style="{ padding: '0.5rem 1rem' }">
              <span v-if="loading">
                <a-table class="w-100" size="small" loading :locale="{ emptyText: 'Подождите...' }"
                  :show-header="false" />
              </span>
              <span v-else>Введите текст для поиска</span>
            </div>
          </div>
          <a-table v-else class="w-100" :columns="columns" size="small" :pagination="{ hideOnSinglePage: true }"
            :data-source="dataFormatted" :loading="loading" row-key="id"
            :row-class-name="(record) => (record.id === 'load-more' ? 'row-load-more' : 'row-show-select-btn')"
            :expanded-row-keys="expandedRowKeys" @expand="onExpandChange" :locale="{ emptyText: 'Ничего не найдено' }"
            :show-header="false">
            <template #expandIcon="{ expanded, record, onExpand, expandable }">
              <span :style="{ marginRight: '0.5rem' }">
                <loading-outlined v-if="record.loading" />
                <right-outlined v-else-if="expandable || record.hasChildren" :rotate="expanded ? 90 : 0"
                  @click="onExpand(record)" />
              </span>
            </template>
            <template #name="{ record }">
              <a class="navy-blue-color-text" v-if="record.id === 'load-more'"
                @click="loadMoreChildren(record)">Ещё...</a>
              <span v-else :title="record.name">{{ record.name }}</span>
            </template>
            <template #actions="{ record }">
              <a-button v-if="record.id !== 'load-more'" size="small" type="primary"
                :disabled="(record?.id === parentId || (parentId === 'root' && record?.name === 'Верхний уровень')) && disableRoot"
                @click="handleSelect(record)">
                Выбрать
              </a-button>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <div class="click-outside-overlay" v-if="isDropdownVisible" @click="isDropdownVisible = false"></div>
  </div>
</template>

<script>
import {
  LoadingOutlined, RightOutlined, CloseCircleFilled,
} from '@ant-design/icons-vue';
import { computed, ref, unref } from "@vue/reactivity";
import { getListData, requestAPI } from "@/compositions/objects";
import { nextTick, onMounted, watch } from "@vue/runtime-core";
import ObjectSearchTable from "@/components/admin/objects/ObjectSearchTable";

export default {
  name: 'ObjectTreeSelect',
  props: {
    modelValue: String, // ObjectId
    placeholder: { type: String, default: 'Выберите объект' },
    maxHeight: { type: String }, // Ограничить высоту выпадушки
    displayAsName: { type: String, default: 'name' }, // Какое поле выовдить в качестве текста инпута
    disableRoot: Boolean, // Запретить взаимодействие с верхним уровнем
    parentId: { default: 'root' },
    hideStructure: Boolean,
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const isDropdownVisible = ref(false);
    const isRootLoaded = ref(false);
    const loadingProgress = ref(false);
    const { getObjectById } = requestAPI();

    const rootLevel = ref({});
    // Выводим только верхний элемент, он разворачивается у нас
    const dataFormatted = computed(() => [unref(rootLevel)] || []);
    const selectedObject = ref(null);
    const placeholderCurrent = ref('');

    const objectId = computed({
      get: () => props.modelValue,
      set: async (newValue) => {
        nextTick(() => emit('update:modelValue', newValue));
      }
    });

    watch(objectId, async (newId) => {
      loadObjectInfo(newId);
      dataMode.value = 'default';
    }, true);

    const {
      loading,
      data, dataMode, parentId,
      search, fetchList, pagination, handleTableChange,
      loadChildren,
      expandedRowKeys,
      onExpandChange,
      loadMoreChildren,
    } = getListData();

    const columns = [
      {
        // ellipsis: true,
        className: 'pretty-wrap',
        slots: { customRender: 'name' },
      },
      {
        className: 'text-right',
        width: '90px',
        slots: { customRender: 'actions', title: 'actionsTitle' },
      },
    ];

    onMounted(async () => {
      parentId.value = props.parentId;
      await loadObjectInfo(unref(objectId));
      placeholderCurrent.value = props.palaceholder;
    });

    // Загрузить рутовый элемент для отображения структуры
    async function loadRoot() {
      loadingProgress.value = true;
      // Добавим в список верхний уровень
      const res = await getObjectById(props.parentId);
      rootLevel.value = res;
      if (props.parentId === 'root') {
        rootLevel.value.name = 'Верхний уровень';
      }
      expandedRowKeys.value = [];
      if (expandedRowKeys.value.indexOf(res?.id) === -1) expandedRowKeys.value.push(res?.id);
      await loadChildren(rootLevel.value);
      loadingProgress.value = false;
      isRootLoaded.value = true;
    }

    // Загрузить данные по выбранному объекту
    async function loadObjectInfo(id) {
      loadingProgress.value = true;
      if (id) {
        const res = await getObjectById(id);
        if (res?.id) {
          selectedObject.value = res;
          displaySearchText(res);
        }
      } else {
        selectedObject.value = null;
        search.value = undefined;
      }
      nextTick(() => emit('change', unref(selectedObject)));
      loadingProgress.value = false;
    }

    function handleSelect(record) {
      displaySearchText(record)
      objectId.value = record?.id;
      isDropdownVisible.value = false;
    }

    function clearSearch() {
      search.value = '';
      pagination.current = 1;
      pagination.total = 0;
      dataMode.value = 'default';
      setFocus();
    }

    function setFocus() {
      const textArea = document.querySelector(`[id='search-input']`);
      textArea?.focus();
    }

    function displaySearchText(record) {
      const text = record ? record[props.displayAsName] : '';
      search.value = text === 'root' ? 'Верхний уровень' : text;
    }

    watch(isDropdownVisible, (open, close) => {
      if (open) {
        if (!unref(isRootLoaded)) {
          loadRoot();
        }
        placeholderCurrent.value = search.value;
        search.value = '';
      }
      if (close) {
        search.value = placeholderCurrent.value;
        displaySearchText(unref(selectedObject));
      }
    });

    return {
      columns, data, dataMode, dataFormatted, loading, loadingProgress,
      placeholderCurrent,
      expandedRowKeys, onExpandChange, loadMoreChildren, fetchList, pagination, handleTableChange,
      search, objectId, selectedObject, handleSelect, clearSearch,
      isDropdownVisible, isRootLoaded,
    }
  },
  components: {
    ObjectSearchTable,
    LoadingOutlined, RightOutlined, CloseCircleFilled,
  },
}
</script>

<style lang="less">
.ant-calendar-picker-input.ant-input,
.smart-time-field,
.smart-time-field,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}

.tree-select {
  position: relative;


  input.ant-input {
    font-size: 14px;

  }

  .ant-input-suffix {
    pointer-events: none;
  }

  .anticon-down {
    color: #C0C4CC;
    font-size: 12px;
  }

  .ant-search-clear {
    z-index: 110;
  }

  &__dropdown {
    position: absolute;
    z-index: 100;
    padding-bottom: 1rem;

    transition: opacity, transform 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    transform: scaleY(0.9);
    transform-origin: top;
    height: 0;
    max-width: 600px;
    width: 100%;

    &.visible {
      opacity: 1;
      transform: none;
      pointer-events: all;
      height: auto;
    }

    &__content {
      border-radius: @border-radius-base;
      background-color: white;
      overflow: hidden;
      overflow-y: auto;

      padding: 0.25rem 0;
      margin: 0.25rem 0;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }

    .ant-table {
      font-size: 14px;

      td {
        min-height: 40px;
      }
    }
  }

  .click-outside-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 99;
  }
}

.ant-table {
  .tree-select {
    background-color: white !important;
    border: 1px solid fade(@light-gray, 50%) !important;
    border-radius: @border-radius-base;

    .ant-input-affix-wrapper,
    .ant-input {
      border: none !important;
      background: transparent;
    }

    .ant-input {
      padding-right: 36px;
      text-overflow: ellipsis;
    }

    &.open {
      .ant-input {
        z-index: 105;
      }

    }
  }

  .ant-table-row.header td {
    padding: 4px 10px;
  }
}
</style>
